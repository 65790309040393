import React, { useEffect, useState } from 'react';
import './App.css';
import Lottie from 'lottie-react';
import tshirtAnimation from './tshirt-animation.json';

const App = () => {
  const [showTagline, setShowTagline] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [taglineVisible, setTaglineVisible] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowTagline(true);
    }, 1000); // Show tagline after 1 second

    const timer2 = setTimeout(() => {
      setTaglineVisible(true); // Tagline fades out
    }, 4000); // Tagline fade out after 3 seconds

    const timer3 = setTimeout(() => {
      setShowAnimation(true);
    }, 4000); // Show animation after tagline fades out

    const timer4 = setTimeout(() => {
      setShowCompanyName(true); // Show company name after animation
    }, 7000); // Show company name after 3 seconds of animation

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, []);

  return (
    <div className="landing-page">
      
      {showTagline && (
        <div className={`tagline-container ${taglineVisible ? 'fade-out' : ''}`}>
          <p className="tagline">Transforming Thoughts into Threads</p>
          <p className="tagline-2 ">Your Vision 👀, Our AI 🤖</p>
          
        </div>
      )}

      {showAnimation && (
        <div className="animation-container">
          <Lottie animationData={tshirtAnimation} loop={true} />
        </div>
      )}
      {showAnimation && showCompanyName && (
        <h1 className="company-name">BrewMyTee🍺</h1>
        
      )}
      {showAnimation && showCompanyName && (
        <h3 className="comingsoon">Sooner on the stages</h3>
        
      )}
      {showAnimation && (
        <div className="bottom-content">
          <h4>Prompt. Print. Perfect. Your Custom Tee Awaits!</h4>
          <p className="contact-email">tobrewmytee@gmail.com</p>
        </div>
      )}
    </div>
  );
};

export default App;
